import React, { Suspense } from 'react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { ToastContainer } from 'react-toastify'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import publicRoutes from './routes'
import './assets/css/bootstrap.css'
import GlobalStyle from './globalStyles'
import Loader from './components/common/Loader'

import 'react-toastify/dist/ReactToastify.css'

function App() {
  return (
    <div className="App">
      <GoogleReCaptchaProvider
        reCaptchaKey={process.env.REACT_APP_RECAPTCHA_CLIENT_KEY}
      >
        <Router>
          <GlobalStyle />
          <ToastContainer
            position="bottom-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <Suspense fallback={<Loader />}>
            <Switch>
              {publicRoutes.map((route) => (
                <Route
                  key={route.path}
                  path={route.path}
                  exact={route.exact}
                  component={route.component}
                />
              ))}
            </Switch>
          </Suspense>
        </Router>
      </GoogleReCaptchaProvider>
    </div>
  )
}

export default App
